import { render, staticRenderFns } from "./PlaceOrder.vue?vue&type=template&id=1a6b6292&scoped=true&"
import script from "./PlaceOrder.vue?vue&type=script&lang=js&"
export * from "./PlaceOrder.vue?vue&type=script&lang=js&"
import style0 from "./PlaceOrder.vue?vue&type=style&index=0&id=1a6b6292&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a6b6292",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VApp,VBtn,VCol,VContainer,VForm,VRadio,VRadioGroup,VRow,VSnackbar,VTextarea})
